export const RoutePaths = {
  HOME: '/',
  CASE_STUDY_SOSTENA: '/case-studies/sostena',
  CASE_STUDY_PATHABLE: '/case-studies/pathable',
  CASE_STUDY_METEOR: '/case-studies/meteor',
  CASE_STUDY_PETPARKER: '/case-studies/pet-parker',
  CASE_STUDY_MONTIAPM: '/case-studies/monti-apm',
  CASE_STUDY_AKLIVITY: '/case-studies/aklivity',
  CASE_STUDY_UNLOC: '/case-studies/unloc',
  CASE_STUDY_LEMPIRE: '/case-studies/lempire',
  CASE_STUDY_SNEAKPEEK: '/case-studies/sneakpeek',
  CASE_STUDY_EATFRESH: '/case-studies/eatfresh',
  CASE_STUDY_FAVRO: '/case-studies/favro',
  CASE_STUDY_BEMARKE: '/case-studies/bemarke',
  CASE_STUDY_POLIDIGITAL: '/case-studies/polidigital',
  CASE_STUDY_ZCLOUD: '/case-studies/zcloud',
  CASE_STUDIES: '/case-studies',
  SERVICES_WEB_DEV: '/services/web-development',
  SERVICES_MOBILE_DEV: '/services/mobile-development',
  SERVICES_DESIGN: '/services/product-design',
  SERVICES_CONSULTING: '/services/consulting',
  SERVICES_AI: '/services/ai',
  SERVICES_PERFORMANCE_ANALYSIS: '/services/performance-analysis',
  SERVICES_DEVOPS: '/services/devops',
  SERVICES_MARKETING_WEBSITES: '/services/marketing-websites',
  SERVICES_METEOR: '/services/meteor',
  REFERRAL: '/referral',
  PRIVACY_POLICY: '/privacy-policy',
  EDUCATION: '/education',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ABOUT_US: '/about-us'
};
